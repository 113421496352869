import Head from "next/head";
import type { NextPage } from "next";
import Hero from "~~/components/Hero";

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Voltech App</title>
        <meta name="description" content="Investment for retail investors." />
      </Head>

      <div className="flex flex-col justify-around mt-0 flex-1 ">
        <Hero />
      </div>
    </>
  );
};

export default Home;
