import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

export default function Hero() {
  const router = useRouter();
  return (
    <div className="relative isolate overflow-hidden">
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
      </svg>
      <div
        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
        aria-hidden="true"
      >
        <div
          className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
          style={{
            clipPath:
              "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 pb-12 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8 ">
          <div className="flex lg:hidden flex-col pb-6 pt-6 items-center justify-center">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none ">
              <Image
                src="/assets/landingPage.png"
                alt="App screenshot"
                width={500}
                height={516}
                className="lg:hidden flex w-[36rem] overflow-hidden rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
              />
            </div>
            {/* <h1 className="text-white text-xl font-bold pt-2 font-poppins">
              VOLTECH
            </h1> */}
          </div>
          <div className="mt-16 sm:mt-16 lg:mt-16">
            <Link href="/whitepaper.pdf" className="inline-flex space-x-6">
              {/* <span className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">
                What's new
              </span> */}
              <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                <span>Learn more</span>
                <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </span>
            </Link>
          </div>
          <h1 className="mt-10 text-4xl font-poppins font-bold tracking-tight text-white sm:text-6xl">
            Ultra-accessible Tokenized Staking Protocol
          </h1>
          <p className="mt-6 text-lg font-openSans leading-8 text-gray-300">
            Voltech aims to bring about only the most profitable and trustworthy investments for all of our stakers. Our
            unique tokenomics actively feed the $VOLT token price to ensure its continual growth. Investing in only the
            most trustworthy and profitable sources guarantees our investors sustainable returns and peace at mind no
            matter the circumstances.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <button
              className="btn-primary btn"
              onClick={() => {
                router.push("/otc");
              }}
            >
              Buy $VOLT
            </button>
            <button
              className="btn-secondary btn"
              onClick={() => {
                router.push("/whitepaper.pdf");
              }}
            >
              Whitepaper
            </button>
          </div>
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none ">
            <Image
              src="/assets/landingPage.png"
              alt="App screenshot"
              width={600}
              height={600}
              className="hidden lg:flex w-[36rem] overflow-hidden rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
